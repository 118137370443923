import React from 'react';
import styled from 'styled-components';
import { Button, Popconfirm, Tooltip } from 'antd';
import { IconSvg } from '@iso/components/ScrumBoard/IconSvg/IconSvg';
import RemoveIcon from '@iso/assets/images/icon/02-icon.svg';
import ShareIcon from '@iso/assets/images/icon/03-icon.svg';
import CloseIcon from '@iso/assets/images/icon/07-icon.svg';
import Box from '../../../../../components/utility/box';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
`;

const ActionButtons = styled.div`
  > img {
    margin-right: 15px;
    border: none;
    width: 40px;
    margin-left: -5px;
  }
`;

const IconButtons = styled.div`
  > img {
    margin-left: 15px;
    margin-right: 0;
  }
`;
export default function CreateTaskHeader({
  buttonContent,
  editing,
  values,
  onCancel,
  onDelete,
  onSave,
  onUpdate,
  onEditCancel,
  openBasicView,
  openCodeView,
}) {
  return (
    <Container style={{position:'absolute', zIndex:'10', width:'100%', top:'0', backgroundColor: "#132f44"}}>
      {console.log("Button content: ", buttonContent)}
      {["apps", "data sources", "queues", "cluster", "cloudflare", "gcp", "ecr", "gitlab", "dockerhub", "security", "scan now", "schedule later", "scan", "pgl stack", "nginx ingress controller", "ingress alb controller", "scan outcome", "configure action", "configure condition", "add condition", "configure frequency", "k8s dashboard", "one-click cluster", "argoflow", "pre-build", "post-build", "post-deploy", "secret detection", "environment", "tool 3", "trivy", "deploy", "configure source", "configure build", "configure deploy", "pre build script", "post build script", "select template"].includes(buttonContent.toLowerCase()) && 
      <Box title={<p> {buttonContent} {buttonContent !== "One-click cluster" && "Details"} </p>}
        subtitle={["one-click cluster"].includes(buttonContent.toLowerCase()) ? <p>Detailed information regarding {buttonContent.toLowerCase()}</p> : <p> Additional details related to {buttonContent.toLowerCase()} </p>}
        titleStyle={{color: "white"}}
        subTitleStyle={{color: "white", fontFamily: "Nunito Sans"}}
        style={{height: "83px", backgroundColor: "#132f44", border: "0", margin: "0"}}
      />}
      {["others", "atmosly managed", "user managed"].includes(buttonContent.toLowerCase()) && <Box title={<p> {buttonContent} </p>}
        subtitle={<p> Additional details related to {buttonContent.toLowerCase()} </p>}
        titleStyle={{color: "white"}}
        subTitleStyle={{color: "white", fontFamily: "Nunito Sans"}}
        style={{height: "83px", backgroundColor: "#132f44", border: "0", margin: "0"}}
      />}
      {/* {buttonContent === "security" && <Box title={<p> Vulnerability Details </p>}
        subtitle={<p> Enter additional details related to security vulnerabilities </p>}
        titleStyle={{color: "white"}}
        subTitleStyle={{color: "white", fontFamily: "Nunito Sans"}}
        style={{height: "83px", backgroundColor: "#132f44"}}
      />} */}
      <ActionButtons>
        {console.log("Button content: ", buttonContent)}
        <IconSvg src={CloseIcon} onClick={onCancel} />
        {!["thirdParties"].includes(buttonContent) ? 
          (
            (!["apps", "data sources", "queues", "cluster", "addons", "security", "scan outcome", "cloudflare", "gcp", "ecr", "gitlab", "dockerhub", "scan now", "schedule later", "scan", "others", "atmosly managed", "user managed", "pgl stack", "nginx ingress controller", "ingress alb controller", "configure action", "configure condition", "add condition", "configure frequency", "k8s dashboard", "one-click cluster", "argoflow", "pre-build", "post-build", "post-deploy", "secret detection", "environment", "tool 3", "trivy", "deploy", "configure source", "configure build", "configure deploy", "pre build script", "post build script", "select template"].includes(buttonContent.toLowerCase())) && (
              <>
                <Button htmlType="submit" type="primary" onClick={onCancel}>
                  {buttonContent ? buttonContent : (values && !values.editing ? 'Save' : 'Update')}
                </Button>
                <Button htmlType="submit" type="primary" onClick={openBasicView} style={{margin: "0 10px"}}>
                  {buttonContent ? "Edit Form" : (values && !values.editing && 'Edit Form')}
                </Button>
                <Button htmlType="submit" type="primary" onClick={openCodeView} style={{margin: "0 10px"}}>
                  {buttonContent ? "Edit Yaml" : (values && !values.editing && 'Edit Yaml')}
                </Button>
                {values && values.editing && onEditCancel && (
                  <Button
                    type="default"
                    onClick={onEditCancel}
                    style={{ marginLeft: 16 }}
                  >
                    Cancel
                  </Button>
                )}
              </>
            )
          )
          : (
            (buttonContent === "Security" || buttonContent === "Scan Outcome") ? <></> : 
            (buttonContent === "Scan Now" || buttonContent === "Schedule Later" || buttonContent === "Scan") ? <></> : 
            <>
              <Button htmlType="submit" type="primary" onClick={!editing ? onSave : onUpdate}>
                {!editing ? (["gitlab"].includes(buttonContent.toLowerCase()) ? 'Connect' : 'Save') : 'Update'}
              </Button>
              {values && values.editing && onEditCancel && (
                <Button
                  type="default"
                  onClick={onEditCancel}
                  style={{ marginLeft: 16 }}
                >
                  Cancel
                </Button>
              )}
            </>
          )
        }
      </ActionButtons>

      <IconButtons>
        {values && values.editing && (
          <Popconfirm
            title="Are you sure delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={onDelete}
          >
            <IconSvg src={RemoveIcon} />
          </Popconfirm>
        )}
      </IconButtons>
    </Container>
  );
}
